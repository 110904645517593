


































































import { DayMapValue } from '@/apis/models/QuizEventModel';
import { WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';
import TimeFunction from '@/libs/timeFunctions';
import Vue from 'vue';

const MODAL_WIDTH = 656;
export default Vue.extend({
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      scheduleSend: '',
      quizGroup: [] as WorkspaceObjectModel[],
      seletedQuizGroups: [] as WorkspaceObjectModel[],
      multipleDays: [] as DayMapValue[],
      fromReschedule: [] as boolean[],
    };
  },
  computed: {
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
  },
  methods: {
    beforeOpen(event: any) {
      this.quizGroup = event.params.quizGroup;
      this.seletedQuizGroups = event.params.seletedQuizGroups;
      this.multipleDays = event.params.multipleDays;
      this.fromReschedule = event.params.fromReschedule;
    },
    cencelReschedule() {
      console.log('取消重送');
      this.$modal.hide('reschedule-modal');
      this.scheduleSend = '';
    },
    reschedule() {
      if (this.scheduleSend) {
        const date = new Date(this.scheduleSend).toISOString();
        this.multipleDays.push({
          id: TimeFunction.getOnlyDateString(date),
          date,
        });
        this.multipleDays.sort((a, b) => {
          const result = TimeFunction.dateTimeStringToTimestamp(a.date) - TimeFunction.dateTimeStringToTimestamp(b.date);
          return result;
        });

        this.fromReschedule[0] = true;
        console.log('fromReschedule', this.fromReschedule[0]);

        const index = this.multipleDays.map((item) => item.date).indexOf(date);

        if (this.quizGroup && this.quizGroup[0]) {
          this.seletedQuizGroups.splice(index, 0, this.quizGroup[0]); // 從index的位置開始，刪除0個元素並插入this.quizGroup
        }

        console.log('確定重送');
        this.$modal.hide('reschedule-modal');
        this.scheduleSend = '';
      }
    },
  },
});
