



















































































































































































































import {
  DayMapValue, HighlightCalendar, MultipleDatesAttributes, QuizEventModelv4, SentDataRecord,
} from '@/apis/models/QuizEventModel';
import TimeFunction from '@/libs/timeFunctions';
import ScheduleResult from '@/components/test_maker/ScheduleResult.vue';
import Vue, { PropType } from 'vue';
import GuidedTourItem from '@/components/GuidedTourItem.vue';
import { WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';

export default Vue.extend({
  components: {
    ScheduleResult,
    GuidedTourItem,
  },
  props: {
    quizEvent: {
      type: Object as PropType<QuizEventModelv4 | null>,
      required: true,
    },
    dateTime: {
      type: Object,
      default: () => ({
        scheduledTime: '',
      }),
    },
    seletedQuizGroups: {
      type: Array as () => Array<WorkspaceObjectModel>,
      default: () => ([
      ]),
    },
    scheduleSetting: {
      type: Object,
      default: () => ({
      }),
    },
    scheduledTime: {
      type: String,
      default: '',
    },
    startValid: {
      type: String,
      default: '',
    },
    endValid: {
      type: String,
      default: '',
    },
    unsendDates: {
      type: Array as () => Array<string>,
      default: () => ([
      ]),
    },
    sentDataList: {
      type: Array as () => Array<SentDataRecord>,
      default: () => ([
      ]),
    },
    showGuidedTourItem: {
      type: Boolean,
      default: false,
    },
    recordCodes: {
      type: Array as () => Array<string| null>,
      default: () => ([
      ]),
    },
    sentRecordCodes: {
      type: Array,
      default: () => ([
      ]),
      required: false,
    },
  },
  data() {
    return {
      multipleDays: [] as DayMapValue[],

      allowSchedule: true,
      allowRepeat: true,
      repeatPeriod: 'week', // 'days'
      repeatFrequency: 1,
      weeklyScheduledDay: ['1'],

      scheduledDateTime: null as null | string,

      scheduledSendDate: null as null | string,
      scheduledSendTime: null as null | string,

      fromReschedule: [
        false,
      ],
    };
  },
  computed: {
    correctSequence(): SentDataRecord[] {
      const list = [] as SentDataRecord[];
      this.sentDataList.forEach((element) => {
        list.push(element);
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      list.sort((a: SentDataRecord, b: SentDataRecord) => {
        if (a.scheduledTime > b.scheduledTime) {
          return 1;
        }
        return -1;
      });
      return list;
    },
    seletedDates(): string[] { // 點點
      const arr = [] as string[];
      this.multipleDays.forEach((day) => {
        arr.push(day.date);
      });
      return arr;
    },
    attributes(): MultipleDatesAttributes[] {
      const arr = [] as MultipleDatesAttributes[];
      for (let index = 0; index < this.seletedDates.length; index += 1) {
        if (index === 0) {
          let label = '';
          if (this.seletedQuizGroups[index]) {
            label = this.seletedQuizGroups[index].metadata.name;
          } else {
            label = '---';
          }
          arr.push({
            highlight: {
              color: 'red',
              fillMode: 'solid',
            },
            dates: this.seletedDates[index],
            popover: {
              label,
            },
          });
        } else {
          let label = '';
          let highlight = {} as HighlightCalendar;
          if (this.seletedQuizGroups[index]) {
            label = this.seletedQuizGroups[index].metadata.name;
            highlight = {
              color: 'blue',
              fillMode: 'solid',
            };
          } else {
            label = '---';
            highlight = {
              color: 'blue',
              fillMode: 'outline',
            };
          }
          arr.push({
            highlight,
            dates: this.seletedDates[index],
            popover: {
              label,
            },
          });
        }
      }
      return arr;
    },

    workspaceId(): string {
      return this.$store.state.currentWorkspace.uuid;
    },
    quizEventId(): string {
      return this.$store.state.currentQuizEvent.uuid;
    },
  },
  watch: {
    seletedDates() {
      this.saveScheduleDateTime();
    },
    scheduledDateTime() {
      if (this.allowSchedule && !this.scheduledDateTime) {
        this.allowRepeat = true;
      }
      this.setInitDateList();
      this.watchInitDatePosition();
      this.calendarQuantityRule(false);
    },
    quizEvent() {
      this.initDefaultDate();
    },
    seletedQuizGroups() {
      if (this.seletedQuizGroups.length === 0) {
        this.allowRepeat = true;
      }
      this.calendarQuantityRule(false);
    },
  },
  mounted() {
    this.initDefaultDate();
    this.checkRepeatAllow();

    if (this.unsendDates) {
      this.unsendDates.forEach((date: string) => {
        this.multipleDays.push({
          id: TimeFunction.getOnlyDateString(date),
          date,
        });
      });
    }

    this.allowRepeat = this.scheduleSetting.allowRepeat;
    this.repeatPeriod = this.scheduleSetting.repeatPeriod;
    this.repeatFrequency = this.scheduleSetting.repeatFrequency;
    this.weeklyScheduledDay = this.scheduleSetting.weeklyScheduledDay;

    if (this.quizEvent?.quizGroups) {
      if (this.quizEvent?.quizGroups?.length > 0 && !this.dateTime.scheduledTime) {
        this.allowRepeat = false;
        this.allowSchedule = false;
      }
    }
  },
  methods: {
    copyEventCode(shortId: string, recordCode: string) {
      if (!shortId) {
        throw new Error('quiz event not ready');
      }
      let copyText = `${shortId}.${recordCode}`;
      if (!recordCode) {
        copyText = shortId;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.$copyText(copyText).then((e) => {
        console.log(copyText);
      }, (e) => {
        console.error('複製失败', e.text);
      });
    },
    closeSchedule() {
      if (this.allowRepeat) {
        this.allowRepeat = false;
      }
      this.scheduledDateTime = '';
    },
    setRule() {
      if (this.checkRepeatAllow()) {
        this.makeRuleList('scheduledDateTime');
      }
    },
    calendarQuantityRule(onDayClick: boolean) {
      if (this.fromReschedule[0] === false) { // 依照正常程序加入的狀況(非重送)
        if (this.allowRepeat && this.multipleDays.length > 0) { // 週期性發送且日期有1個以上(有起始日)才需要
          if (this.seletedQuizGroups.length > this.multipleDays.length) { // 題組 > 日期
            // console.log('題組比日期多項, 要從後面加日期');
            if (this.checkRepeatAllow()) {
              this.makeRuleList(this.multipleDays[this.multipleDays.length - 1].date); // 從最後一個日期開始算起補滿
            }
          } else { // 日期 > 題組
            if (!onDayClick) { // 如果是用selecter刪除的 就從最後一個日期開始刪
              let n = 0;
              this.attributes.forEach((element) => {
                if (element.popover.label === '---') {
                  n += 1;
                }
              });
              this.multipleDays.splice(this.multipleDays.length - n, n);
            }
            console.log('有多的日期 已刪掉');
          }
        }
      }
    },
    removeDate(idx: number) {
      this.seletedQuizGroups.splice(idx, 1);
      this.multipleDays.splice(idx, 1);
      if (this.multipleDays[0]) {
        this.scheduledDateTime = this.multipleDays[0].date;
      } else {
        this.scheduledDateTime = '';
      }
    },
    onDayClick(day: DayMapValue) {
      let newDate = day.date;
      if (this.scheduledSendTime) {
        const timestamp = TimeFunction.dateTimeStringToTimestamp(day.date);
        const dateString = TimeFunction.getDateStringObject(timestamp / 1000).date;
        newDate = TimeFunction.toISODateTime(dateString, this.scheduledSendTime);
      }
      const idx = this.multipleDays.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.multipleDays.splice(idx, 1);
      } else {
        this.multipleDays.push({
          id: day.id,
          date: newDate,
        });
      }
      this.multipleDays.sort((a, b) => {
        const result = TimeFunction.dateTimeStringToTimestamp(a.date) - TimeFunction.dateTimeStringToTimestamp(b.date);
        return result;
      });
      if (this.scheduledDateTime) {
        const dateTime = new Date(TimeFunction.dateTimeStringToTimestamp(this.scheduledDateTime)).toISOString();
        if (dateTime !== this.seletedDates[0]) {
          const initDay = this.seletedDates[0];
          this.scheduledDateTime = initDay;
        }
      }

      this.calendarQuantityRule(true);
      this.saveScheduleDateTime();
    },
    initDefaultDate() {
      if (!this.quizEvent) {
        throw new Error('quiz event not ready');
      }

      if (this.dateTime.scheduledTime) {
        const scheduledTimestamp = TimeFunction.dateTimeStringToTimestamp(this.dateTime.scheduledTime);
        this.scheduledDateTime = new Date(scheduledTimestamp).toISOString(); // scheduleSend是配合input的格式
        // this.getScheduleSendDateTime(); // 這行應該不必加
      } else {
        this.scheduledDateTime = '';
      }
    },
    getScheduleSendDateTime() { // 從scheduledTimeISO 取出 scheduledSendDate scheduledSendTime
      if (this.scheduledDateTime) {
        const timestamps = TimeFunction.dateTimeStringToTimestamp(this.scheduledDateTime);
        const object = TimeFunction.getDateStringObject(timestamps / 1000);
        this.scheduledSendDate = object.date;
        this.scheduledSendTime = object.time;
      }
    },
    checkRepeatAllow() {
      let isAllowRepeat = false;
      if (this.allowRepeat && this.scheduledDateTime) {
        if (!this.repeatPeriod) { // 查看是否有資料從後端來 沒有的話就給他初始值
          this.repeatPeriod = 'week';
          this.repeatFrequency = 1;
          this.weeklyScheduledDay = ['1'];
        }

        if (this.repeatPeriod === 'week') {
          this.repeatFrequency = 1;
          if (!this.weeklyScheduledDay || this.weeklyScheduledDay.length === 0) {
            this.weeklyScheduledDay = ['1'];
          }
          isAllowRepeat = true;
        }

        if (this.repeatPeriod === 'days') {
          this.weeklyScheduledDay = ['1'];
          isAllowRepeat = true;
        }
      } else { // 恢復原始設定
        this.multipleDays = []; // 清空
        this.setInitDateList(); // 放入起始日
        this.repeatPeriod = 'week';
        this.repeatFrequency = 1;
        this.weeklyScheduledDay = ['1'];
      }
      return isAllowRepeat;
    },
    makeRuleList(startDate: string) {
      if (this.scheduledDateTime) {
        let startDay = 0;
        if (startDate === 'scheduledDateTime') {
          startDay = TimeFunction.dateTimeStringToTimestamp(this.scheduledDateTime) / 1000; // timestamp
          this.multipleDays = []; // 清空
          this.setInitDateList(); // 放入起始日
        } else {
          startDay = TimeFunction.dateTimeStringToTimestamp(startDate) / 1000; // timestamp
        }

        let dateForWeek = startDay;
        let dateForDay = startDay;
        let formatDate = '';

        if (this.repeatPeriod === 'week') {
          while (this.seletedQuizGroups.length > this.multipleDays.length) {
            dateForWeek += 86400;
            const dateDay = TimeFunction.getWeekDay(dateForWeek);
            // eslint-disable-next-line no-loop-func
            this.weeklyScheduledDay.forEach((scheduleNum) => {
              if (dateDay === Number(scheduleNum)) {
                formatDate = TimeFunction.getOnlyDateString(TimeFunction.timestampToDateStringFormat(dateForWeek)); // '2021-09-29'
                if (this.scheduledSendTime) {
                  const result = TimeFunction.toISODateTime(formatDate, this.scheduledSendTime);
                  this.multipleDays.push({
                    id: TimeFunction.getOnlyDateString(result),
                    date: result,
                  });
                }
              }
            });
          }
        }

        if (this.repeatPeriod === 'days') {
          while (this.seletedQuizGroups.length > this.multipleDays.length) {
            dateForDay += this.repeatFrequency * 86400;
            formatDate = TimeFunction.getOnlyDateString(TimeFunction.timestampToDateStringFormat(dateForDay)); // '2021-09-29'
            if (this.scheduledSendTime) {
              const result = TimeFunction.toISODateTime(formatDate, this.scheduledSendTime);
              this.multipleDays.push({
                id: TimeFunction.getOnlyDateString(result),
                date: result,
              });
            }
          }
        }

        this.saveScheduleDateTime();
      }
    },
    watchInitDatePosition() {
      if (this.scheduledDateTime && this.multipleDays[1]) {
        const newStartDay = TimeFunction.dateTimeStringToTimestamp(this.scheduledDateTime); // 變動後的新日期
        const secondDate = TimeFunction.dateTimeStringToTimestamp(this.multipleDays[1].date);// 原本數列中的第二個日期

        if (secondDate <= newStartDay) {
          console.log('會重整');

          this.makeRuleList('scheduledDateTime');
        }

        if (this.fromReschedule[0] === false) { // 依照正常程序加入的狀況(非重送)
          if (this.scheduledSendDate === TimeFunction.getOnlyDateString(this.scheduledDateTime) // 日期沒有變
            && this.scheduledSendTime !== TimeFunction.getDateStringObject(newStartDay).time) { // 但時間變了
            const arr = [] as DayMapValue[];
            this.multipleDays.forEach((item) => {
              if (this.scheduledSendTime) {
                const onlyDate = TimeFunction.getOnlyDateString(item.date);
                const result = TimeFunction.toISODateTime(onlyDate, this.scheduledSendTime);
                arr.push({
                  id: onlyDate,
                  date: result,
                });
              }
            });
            this.multipleDays = [];
            this.multipleDays = arr;
          }
        }
      }
    },
    setInitDateList() { // 把起始日加入DateList的做法
      if (this.scheduledDateTime) {
        this.getScheduleSendDateTime(); // 取得scheduledDateTime的 Date和Time

        if (this.scheduledDateTime && this.scheduledSendTime) {
          const scheduledSendDate = TimeFunction.getOnlyDateString(this.scheduledDateTime);
          const dateISO = TimeFunction.toISODateTime(scheduledSendDate, this.scheduledSendTime);
          const result = {
            id: scheduledSendDate,
            date: dateISO,
          };

          if (this.multipleDays[0]) {
            this.multipleDays.forEach((day: DayMapValue) => {
              const onlyDate = TimeFunction.getOnlyDateString(day.date);
              const timestamp = TimeFunction.dateTimeStringToTimestamp(day.date);
              const onlyTime = TimeFunction.getDateStringObject(timestamp).time;
              if (onlyDate === scheduledSendDate) {
                if (this.scheduledSendTime !== onlyTime) {
                  this.multipleDays.splice(0, 1, result); // 取代第一個
                }
              } else {
                this.multipleDays.splice(0, 1, result); // 取代第一個
              }
            });
          } else {
            this.multipleDays.push(result);
          }
        }
      } else { // 沒有起始日期就沒有list
        this.multipleDays = [];
      }
      this.saveScheduleDateTime();
    },
    saveScheduleDateTime() {
      if (this.scheduledSendTime && this.seletedDates[0]) {
        const latestDate = this.seletedDates[0];
        this.dateTime.scheduledTime = latestDate;
        this.dateTime.dateList.dates = this.seletedDates;

        this.scheduleSetting.allowRepeat = this.allowRepeat;
        this.scheduleSetting.repeatPeriod = this.repeatPeriod;
        this.scheduleSetting.repeatFrequency = this.repeatFrequency;
        this.scheduleSetting.weeklyScheduledDay = this.weeklyScheduledDay;
      } else {
        this.dateTime.scheduledTime = null;
        this.dateTime.dateList.dates = [];
        this.scheduleSetting.allowRepeat = true;
        this.scheduleSetting.repeatPeriod = 'week';
        this.scheduleSetting.repeatFrequency = 1;
        this.scheduleSetting.weeklyScheduledDay = ['1'];
      }
    },
  },
});
