





















































import Vue from 'vue';
import HandleDraggable from '@/components/test_maker/HandleDraggable.vue';
import { WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';

export default Vue.extend({
  components: {
    HandleDraggable,
  },
  props: {
    checkedList: {
      type: Array as () => Array<WorkspaceObjectModel>,
      default: () => ([
      ]),
    },
    groupType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
});
