



























































































































































































import TimeFunction from '@/libs/timeFunctions';
import Vue from 'vue';
import RescheduleModal from '@/components/test_maker/RescheduleModal.vue';
import HandleDraggable from '@/components/test_maker/HandleDraggable.vue';
import { WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';
import { DayMapValue, SentDataRecord } from '@/apis/models/QuizEventModel';
import GuidedTourItem from '@/components/GuidedTourItem.vue';
import Tooltip from '@/components/Tooltip.vue';

export default Vue.extend({
  components: {
    HandleDraggable,
    RescheduleModal,
    GuidedTourItem,
    Tooltip,
  },
  props: {
    startValid: {
      type: String,
      default: '',
    },
    endValid: {
      type: String,
      default: '',
    },
    seletedQuizGroups: {
      type: Array as () => Array<WorkspaceObjectModel>,
      default: () => ([
      ]),
    },
    scheduleSetting: {
      type: Object,
      default: () => ({
      }),
    },
    multipleDays: {
      type: Array as () => Array<DayMapValue>,
      default: () => ([
      ]),
    },
    fromReschedule: {
      type: Array,
      default: () => ([
      ]),
    },
    sentDataList: {
      type: Array as () => Array<SentDataRecord>,
      default: () => ([
      ]),
    },
    allowRepeat: {
      type: Boolean,
      default: true,
    },
    shortId: {
      type: String,
      default: '',
      required: false,
    },
    recordCodes: {
      type: Array,
      default: () => ([
      ]),
      required: false,
    },
    sentRecordCodes: {
      type: Array,
      default: () => ([
      ]),
      required: false,
    },
  },
  data() {
    return {
      validDate: {
        startDate: this.$t('同發送時間').toString() as null | string,
        startTime: null as null | string,
        endDate: this.$t('無限制').toString() as null | string,
        endTime: null as null | string,
      },
    };
  },
  computed: {
    correctSequence(): SentDataRecord[] {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sentDataList.sort((a: SentDataRecord, b: SentDataRecord) => {
        if (a.scheduledTime > b.scheduledTime) {
          return 1;
        }
        return -1;
      });
      return this.sentDataList;
    },
    showGuidedTour(): boolean {
      return this.$store.state.showGuidedTour;
    },
    showGuidedTourItem(): boolean {
      if (this.showGuidedTour && this.$route.path === '/manager/quizevent-maker') {
        return true;
      }
      return false;
    },
  },
  watch: {
    startValid() {
      this.init();
    },
    endValid() {
      this.init();
    },
    seletedQuizGroups() {
      this.$emit('saveScheduleDateTime');
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    copyEventCode(recordCode: string) {
      if (!this.shortId) {
        throw new Error('quiz event not ready');
      }
      let copyText = `${this.shortId}.${recordCode}`;
      if (recordCode === 'none') {
        copyText = `${this.shortId}`;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.$copyText(copyText).then((e) => {
        console.log(copyText);
      }, (e) => {
        console.error('複製失败', e.text);
      });
    },
    removeDate(idx: number) {
      this.$emit('removeDate', idx);
    },
    hasQuiz(uuid: string) {
      let hasQuiz = false;
      this.seletedQuizGroups.forEach((quiz: WorkspaceObjectModel) => {
        if (uuid === quiz.uuid) {
          hasQuiz = true;
        }
      });
      return hasQuiz;
    },
    init() {
      if (this.startValid || this.endValid) {
        if (this.startValid) {
          const startTimestamps = TimeFunction.dateTimeStringToTimestamp(this.startValid);
          const object = TimeFunction.getDateStringObject(startTimestamps / 1000);
          this.validDate.startDate = object.date;
          this.validDate.startTime = object.time;
        } else {
          this.validDate.startDate = this.$t('同發送時間').toString();
          this.validDate.startTime = null;
        }
        if (this.endValid) {
          const endTimestamps = TimeFunction.dateTimeStringToTimestamp(this.endValid);
          const object = TimeFunction.getDateStringObject(endTimestamps / 1000);
          this.validDate.endDate = object.date;
          this.validDate.endTime = object.time;
        } else {
          this.validDate.endDate = this.$t('無限制').toString();
          this.validDate.endTime = null;
        }
      } else {
        this.validDate.startDate = this.$t('同發送時間').toString();
        this.validDate.startTime = null;
        this.validDate.endDate = this.$t('無限制').toString();
        this.validDate.endTime = null;
      }
    },
    openRescheduleModal(quizGroup: WorkspaceObjectModel) {
      this.$modal.show('reschedule-modal',
        {
          quizGroup, seletedQuizGroups: this.seletedQuizGroups, multipleDays: this.multipleDays, fromReschedule: this.fromReschedule,
        });
    },
    // 轉換時間-----------------------------
    displayDateFormat(date: string) {
      const str = TimeFunction.getOnlyDateString(date);
      return str;
    },
    displayTimeFormat(date: string) {
      const timestamp = new Date(date).getTime();
      const rescheduleDate = TimeFunction.getDateStringObject(timestamp / 1000);
      return rescheduleDate.time;
    },
  },
});
