<template>
  <div>
    <draggable tag="ul" :list="checkedList" class="list-group" handle=".handle" :class="{scheduled: display === 'scheduled'}">
      <div
        v-for="(item, idx) in checkedList"
        :key="idx"
        class="item"
      >
        <div class="item-area" :class="{'info-highlight': item.isExamMode}">
          <i class="fa fa-align-justify handle" />
          <div class="index">
            {{ idx+1 }}
          </div>
          <!-- 一般題組 -->
          <div v-if="item.metadata.type === 'WorkspaceQuizGroup'" class="info">
            <div class="file-name">
              {{ item.metadata.name }}
            </div>
            <div class="number">
              {{ $tc('n題', item.quizNumber, {n: item.quizNumber}) }}
            </div>
          </div>
          <!-- 資料夾題組 -->
          <div v-if="item.metadata.type === 'WorkspaceFolder'" class="info">
            <div class="file-name">
              {{ item.metadata.name }}
            </div>
            <div class="number">
              {{ $t('資料夾') }}
            </div>
          </div>
          <!-- 共享題組 -->
          <div v-if="item.metadata.type === 'WorkspaceSoftLink'" class="info">
            <div class="file-name">
              {{ item.sourceObject.metadata.name }}
              <i class="fas fa-user-friends shared-item" />
            </div>
            <div class="number">
              {{ $tc('n題', item.sourceObject.quizNumber,{n: item.sourceObject.quizNumber}) }}
            </div>
          </div>
          <i v-if="display !== 'scheduled'" class="fa fa-times close" @click="removeCheckedList(idx)" />

          <div v-if="display === 'scheduled'" class="ui-btn-right icon-area">
            <div class="action-icon">
              <i class="fas fa-user-edit exam-icon"
                 :class="{'helpful': helpfulData, 'icon-highlight': item.isExamMode}"
                 @click="setExamMode(item)"
              />
            </div>
            <div class="action-icon">
              <b-dropdown class="action-menu" variant="none" no-caret toggle-class="text-decoration-none" boundary="window" dropright>
                <template #button-content>
                  <div class="icon-btn">
                    <i class="fas fa-ellipsis-v" />
                  </div>
                </template>

                <b-dropdown-item v-if="!recordCodes[idx] && multipleDays.length > 1">
                  {{ $t('複製代碼請先儲存測驗') }}
                </b-dropdown-item>
                <b-dropdown-item v-if="recordCodes[idx] && multipleDays.length > 1" class="copyCode" @click="copyEventCode(recordCodes[idx])">
                  <span>{{ $t('複製代碼') }} {{ shortId }}.{{ recordCodes[idx] }}</span>
                  <span><i class="far fa-copy" /></span>
                </b-dropdown-item>
                <b-dropdown-divider v-if="recordCodes[idx] && multipleDays.length > 1" />
                <b-dropdown-item class="removeBtn" @click="removeAt(idx)">
                  <p>{{ $t('移除') }}<i class="fa fa-times" /></p>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'Handle',
  display: 'Handle',
  components: {
    draggable,
  },
  props: {
    checkedList: {
      type: Array,
      default: () => ([
      ]),
    },
    mapData: {
      type: Object,
      default: () => ({
      }),
    },
    display: {
      type: String,
      default: '',
      required: false,
    },
    multipleDays: {
      type: Array,
      default: () => ([
      ]),
      required: false,
    },
    shortId: {
      type: String,
      default: '',
      required: false,
    },
    recordCodes: {
      type: Array,
      default: () => ([
      ]),
      required: false,
    },
  },
  data() {
    return {
      helpfulData: false, // for class="helpful" I don't know the reason but it works.
    };
  },
  computed: {
    // recordCodes() {
    //   let list = [];
    //   console.log(this.checkedList.length, this.recordCodes);
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   // list = this.recordCodes.splice(0, this.recordCodes.length - this.checkedList.length);
    //   list = this.recordCodes;
    //   console.log(list, this.recordCodes.length, this.checkedList.length);
    //   return list;
    // },
  },
  methods: {
    setExamMode(item) {
      item.isExamMode = !item.isExamMode;
      this.helpfulData = !this.helpfulData; // for class="helpful" I don't know the reason but it works.
    },
    removeCheckedList(idx) {
      this.checkedList.splice(idx, 1);
    },
    removeAt(idx) {
      // this.checkedList.splice(idx, 1); // 多的 會影響
      this.$emit('removeDate', idx);
      console.log(idx);
    },

    // copycode---------
    copyEventCode(recordCode) {
      if (!this.shortId) {
        throw new Error('quiz event not ready');
      }
      const copyText = `${this.shortId}.${recordCode}`;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.$copyText(copyText).then((e) => {
        console.log(copyText);
      }, (e) => {
        console.error('複製失败', e.text);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.item{
  width: 100%;
  background-color: whitesmoke;
  .item-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    i, .index{
      width: 5%;
      font-size: 1rem;
    }
    .handle, .close{
      opacity: 0.5;
      &:hover{
        opacity: 1;
      }
      &.handle:hover{
        cursor: grab;
      }
      &.close:hover{
        cursor: pointer;
      }
    }
    .info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      .file-name{
        width: 80%;
        text-align: left;
        overflow : hidden;
        text-overflow : ellipsis;
        white-space : nowrap;
        .shared-item{
          font-size: 0.5rem;
          padding: 0 10px;
          color: #555;
        }
      }
      .number{
        width: 30%;
      }
    }
  }
}
.item + .item{
  margin-top: 5px;
}

.scheduled{
  .item{
    margin: 8px 0;
    .item-area {
      padding: 0 10px;
      margin-right: 3px;
      i, .index{
        width: auto;
        font-size: 1rem;
      }
      .index{
        display: none;
      }
      .handle{
        order: 0;
      }
      .icon-area{
        display: flex;
        justify-content: space-around;
        order: 2;
        width: 20%;
        .action-icon{
          display: flex;
          i{
            opacity: 0.3;
            line-height: 23px;
            &:hover{
              opacity: 1;
              cursor: pointer;
            }
          }
          .exam-icon{
            padding: 0 5px;
          }
          .icon-highlight{
            opacity: 0.8;
          }
          .removeBtn, .copyCode{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            i{
              padding-left: 10px;
              opacity: 1;
            }
            p{
              margin: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
      .info{
        order: 1;
        margin: 0 10px;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .file-name{
          width: 100%;
          .shared-item{
            display: none;
          }
        }
        .number{
          display: none;
        }
      }
    }
    .info-highlight {
      background: rgba(0, 0, 0, 0.134) ;
      border-radius: 3px;
    }

  }
  .item + .item{
    margin-top: 0px;
  }
}
</style>
