

































































import FolderApi from '@/apis/FolderApi';
import { WorkspaceFolderModel, WorkspaceObjectModel, WorkspaceObjectType } from '@/apis/models/WorkspaceObjectModel';
import WorkspaceObjectItem from '@/components/test_maker/WorkspaceObjectItem.vue';
import { SelectionEventBus } from '@/libs/EventBus';
import Breadcrumb from '@/components/workspace/Breadcrumb.vue';
import Utils from '@/libs/utils';
import Vue from 'vue';

export default Vue.extend({
  name: 'WorkspaceObjectSelector',
  components: {
    WorkspaceObjectItem,
    Breadcrumb,
  },
  props: {
    localRootId: {
      type: String,
      required: true,
    },
    shareRootId: {
      type: String,
      required: true,
    },
    localRootName: {
      type: String,
      required: true,
    },
    allowSelectMany: {
      type: Boolean,
      default: false,
    },
    defaultSelection: {
      type: Array as () => Array<WorkspaceObjectModel>,
      default: () => [],
    },
  },
  data() {
    return {
      selectModeOpen: true,
      WorkspaceObjectType,
      selection: [] as WorkspaceObjectModel[],
      selectionSet: new Set(),
      currentFolder: null as WorkspaceFolderModel | null,
      isLoading: false,
      searchString: '',
    };
  },
  computed: {
    workspaceObjects(): WorkspaceObjectModel[] {
      if (this.currentFolder) {
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        const fileArr = this.currentFolder.children.filter((object) => object.metadata.type !== WorkspaceObjectType.Folder);
        const folderArr = this.currentFolder.children.filter((object) => object.metadata.type === WorkspaceObjectType.Folder);

        let softLinkArr = this.currentFolder.children.filter((object) => object.metadata.type === WorkspaceObjectType.SoftLink);
        softLinkArr = softLinkArr.filter((object) => object.sourceObject);
        // console.log('softLinkArr', softLinkArr);

        if (softLinkArr.length > 0) {
          return softLinkArr.sort((a, b) => collator.compare(a.sourceObject.metadata.name.toUpperCase().trim(), b.sourceObject.metadata.name.toUpperCase().trim()));
        }

        fileArr.sort((a, b) => collator.compare(a.metadata.name.toUpperCase().trim(), b.metadata.name.toUpperCase().trim()));
        folderArr.sort((a, b) => collator.compare(a.metadata.name.toUpperCase().trim(), b.metadata.name.toUpperCase().trim()));
        const sortedArr = [] as WorkspaceObjectModel[];
        folderArr.forEach((element) => {
          sortedArr.push(element);
        });
        fileArr.forEach((element) => {
          sortedArr.push(element);
        });
        return sortedArr;
      }
      return [];
    },
    trimSearchString(): string {
      // 修掉輸入的空白
      const string = this.searchString.trim();

      return string;
    },

    searchList(): WorkspaceObjectModel[] {
      const result = [] as WorkspaceObjectModel[];
      this.workspaceObjects.forEach((element) => {
        const listName = element.metadata.name;

        const inputName = this.trimSearchString;
        const indexOf = listName.toUpperCase().indexOf(inputName.toUpperCase());
        if (indexOf >= 0) {
          result.push(element);
        }
      });
      return result;
    },

    filteredMethodList(): WorkspaceObjectModel[] {
      if (this.trimSearchString !== '') {
        return this.searchList;
      }
      return this.workspaceObjects;
    },
  },
  mounted() {
    if (this.defaultSelection) {
      this.selection = Utils.deepcopy(this.defaultSelection);
    }

    this.selection.forEach((sel) => {
      this.selectionSet.add(sel.uuid);
    });

    this.visitFolder(this.localRootId);
  },
  methods: {
    async visitFolder(folderId: string) {
      this.isLoading = true;
      try {
        this.currentFolder = await FolderApi.retrieve(folderId);
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          title: this.$t('錯誤').toString(),
          text: this.$t('讀取資料夾失敗，請稍後重試').toString(),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async toggleSelection(object: WorkspaceObjectModel) {
      const index = this.selection.findIndex((item) => item.uuid === object.uuid);

      if (index === -1) {
        if (!this.allowSelectMany) {
          this.selection = [];
          this.selectionSet = new Set();
        }
        this.selection.push(object);
        this.selectionSet.add(object.uuid);

        if (!this.allowSelectMany) {
          await this.$nextTick();
          SelectionEventBus.$emit('reset');
        }
      } else {
        this.selection.splice(index, 1);
        this.selectionSet.delete(object.uuid);
      }
    },
  },
});
