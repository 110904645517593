











import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: '',
    },
    text: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
});
