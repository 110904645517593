





























import Vue from 'vue';
import WorkspaceObjectSelector from '@/components/test_maker/WorkspaceObjectSelector.vue';
import { WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';

export default Vue.extend({
  components: {
    WorkspaceObjectSelector,
  },
  data() {
    return {
      defaultSelection: [] as WorkspaceObjectModel[],
      rootName: '我的班級',
    };
  },
  computed: {
    memberLocalRootId(): string | null {
      if (this.$store.state.currentWorkspace) {
        return this.$store.state.currentWorkspace.memberFolders.local;
      }

      return null;
    },

    memberShareRootId(): string | null {
      if (this.$store.state.currentWorkspace) {
        return this.$store.state.currentWorkspace.memberFolders.share;
      }

      return null;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeOpen(event: any) {
      // import stuff that was selected before
      if (event.params.defaultSelection) {
        this.defaultSelection = event.params.defaultSelection;
      }
    },
    handleSubmit(selection: WorkspaceObjectModel[]) {
      this.$emit('submit-selection', selection);
      this.$modal.hide('select-member-group-modal');
    },
    handleCancel() {
      this.$modal.hide('select-member-group-modal');
    },
  },
});
