

































































import { QuizEventModelv4 } from '@/apis/models/QuizEventModel';
import TimeFunction from '@/libs/timeFunctions';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    dateListDate: { // this.dateList[0].dates[0]
      type: String,
      default: '',
    },
    quizEvent: {
      type: Object as PropType<QuizEventModelv4 | null>,
      required: true,
    },
    dateTime: {
      type: Object,
      default: () => ({
        startValid: '',
      }),
    },
    validData: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      startValidTimeState: 'sameTimeSent', // 'afterSent'
      endValidTimeState: 'sameTimeSent', // 'afterSent'

      startDate: null as null | string,
      startTime: null as null | string,
      endDate: null as null | string,
      endTime: null as null | string,

      startTimeHour: 0,
      startTimeMin: 0,
      endTimeDay: 0,
      endTimeHour: 0,

      scheduleSendDate: null as null | string,
      scheduleSendTime: null as null | string,
    };
  },
  computed: {
    workspaceId(): string {
      return this.$store.state.currentWorkspace.uuid;
    },
    quizEventId(): string {
      return this.$store.state.currentQuizEvent.uuid;
    },
  },
  watch: {
    quizEvent() {
      this.initDefaultDate();
    },
    dateListDate() {
      this.getScheduleSendTime();
      this.saveValidDateTime();
    },
  },
  async mounted() {
    this.initDefaultDate();

    if (this.dateTime.scheduledTime) {
      if (this.startTimeHour !== 0 || this.startTimeMin !== 0) {
        this.startValidTimeState = 'afterSent';
      }
      if (this.endTimeDay !== 0 || this.endTimeHour !== 0) {
        this.endValidTimeState = 'afterSent';
      }
    } else {
      this.startValidTimeState = 'sameTimeSent';
      this.endValidTimeState = 'sameTimeSent';
    }
  },
  methods: {
    changeValidTimeData() {
      if (this.startTimeHour !== 0 || this.startTimeMin !== 0 || this.endTimeDay !== 0 || this.endTimeHour !== 0) {
        this.saveValidDateTime();
      }
    },
    getScheduleSendTime() { // 從scheduleSend 取出 scheduleSendTime
      if (this.dateListDate) {
        const timestamps = TimeFunction.dateTimeStringToTimestamp(this.dateListDate);
        const object = TimeFunction.getDateStringObject(timestamps / 1000);
        this.scheduleSendDate = object.date;
        this.scheduleSendTime = object.time;
      }
    },
    initDefaultDate() {
      if (!this.quizEvent) {
        throw new Error('quiz event not ready');
      }
      this.startTimeHour = this.validData.startTimeHour;
      this.startTimeMin = this.validData.startTimeMin;
      this.endTimeDay = this.validData.endTimeDay;
      this.endTimeHour = this.validData.endTimeHour;
    },

    saveValidDateTime() {
      if (this.scheduleSendDate && this.scheduleSendTime) {
        if (this.startTimeHour !== 0 || this.startTimeMin !== 0) { // 有設定時分的話
          let timestamp = TimeFunction.dateStringsToTimestamp(this.scheduleSendDate, this.scheduleSendTime);
          timestamp += (3600000 * this.startTimeHour + 60000 * this.startTimeMin);
          const result = new Date(timestamp).toISOString();
          this.dateTime.startValid = result;
        } else {
          this.dateTime.startValid = null;
        }
        if (this.endTimeDay !== 0 || this.endTimeHour !== 0) { // 有設定時分的話
          let timestamp = TimeFunction.dateStringsToTimestamp(this.scheduleSendDate, this.scheduleSendTime);
          timestamp += (86400000 * this.endTimeDay + 3600000 * this.endTimeHour);
          const result = new Date(timestamp).toISOString();
          this.dateTime.endValid = result;
        } else {
          this.dateTime.endValid = null;
        }
      } else {
        this.dateTime.startValid = null;
        this.startDate = this.$t('同發送時間').toString();
        this.startTime = '';
        this.dateTime.endValid = null;
        this.endDate = this.$t('無限制').toString();
        this.endTime = '';
        console.log('沒存到scheduleSendTime');
      }
      this.validData.startTimeHour = this.startTimeHour;
      this.validData.startTimeMin = this.startTimeMin;
      this.validData.endTimeDay = this.endTimeDay;
      this.validData.endTimeHour = this.endTimeHour;
    },
  },
});
